import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Theme,
  Tooltip,
  LinearProgress
} from "@material-ui/core";
// import MenuIcon from "@material-ui/icons/Menu";
// import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import MainLogo from "images/mainLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { Refresh } from "@material-ui/icons";

interface RootState {
  PublicLoadboard: {
    closeRequest: boolean;
    publicBoardLoading: boolean;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "none"
    },
    flexGrow: {
      flexGrow: 1
    },
    signOutButton: {
      marginLeft: theme.spacing(1)
    },
    loading: {
      height: 6
    }
  })
);

const Topbar = (props: any) => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles(props);

  const logoutUser = useDispatch();
  const loadDashboard = useDispatch();

  const PublicLoadboard = useSelector(
    (state: RootState) => state.PublicLoadboard
  );

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src={MainLogo} />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Button
          onClick={() => loadDashboard({ type: "LOADBOARD" })}
          startIcon={<Refresh />}
          color="inherit"
        >
          Refresh Board
        </Button>
        <Tooltip title="Logout">
          <IconButton
            onClick={() => logoutUser({ type: "LOGOUT_USER" })}
            className={classes.signOutButton}
            color="inherit"
          >
            <InputIcon />
          </IconButton>
        </Tooltip>
        {/* <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton className={classes.signOutButton} color="inherit">
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden> */}
      </Toolbar>
      {PublicLoadboard.publicBoardLoading ? (
        <LinearProgress className={classes.loading} color="secondary" />
      ) : null}
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
