export const LOADBOARD = "LOADBOARD";
export const PUBLIC_LOADBOARD_LOADING = "PUBLIC_LOADBOARD_LOADING";
export const PUBLIC_LOADBOARD_COMPLETE = "PUBLIC_LOADBOARD_COMPLETE";

export const CONTACT_REQUEST = "CONTACT_REQUEST";
export const CONTACT_REQUEST_LOADING = "CONTACT_REQUEST_LOADING";
export const CONTACT_REQUEST_COMPLETE = "CONTACT_REQUEST_COMPLETE";

export const CONTACT_DETAILS = "CONTACT_DETAILS";
export const CONTACT_DETAILS_REQUEST_LOADING =
  "CONTACT_DETAILS_REQUEST_LOADING";
export const CONTACT_DETAILS_REQUEST_COMPLETE =
  "CONTACT_DETAILS_REQUEST_COMPLETE";
