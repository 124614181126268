export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

export const addNotification = (notification : any) => {
  const key = notification.options && notification.options.key;

  return {
    type: ADD_NOTIFICATION,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  };
};

export const closeNotification = (key: any) => ({
  type: CLOSE_NOTIFICATION,
  dismissAll: !key, // dismiss all if no key has been defined
  key
});

export const dismissNotification = (key: any) => ({
  type: DISMISS_NOTIFICATION,
  key
});

export const DefaultError = {
  type: ADD_NOTIFICATION,
  payload: {
    message: "Unspecified Error",
    options: {
      key: new Date().getTime() + Math.random(),
      variant: "error"
    }
  }
};
