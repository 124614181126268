import { AuthActions } from "@atg/atg-core";

const initialState = {
  user: {},
  userInfo: {},
  isLoading: false,
  loggedIn: false
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case AuthActions.USER_LOGIN:
      return {
        ...state,
        user: payload
      };
    case AuthActions.USER_LOGIN_COMPLETE:
      return {
        ...state,
        userInfo: payload,
        isLoading: false
      };
    case AuthActions.USER_LOGIN_LOADING:
      return {
        ...state,
        ...payload
      };
    case AuthActions.USER_AUTH:
      return {
        ...state,
        loggedIn: payload
      };
    case AuthActions.LOGOUT_USER:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
