import React from "react";
import moment from "moment";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Theme
} from "@material-ui/core";
import { HyphenFormatter } from "@atg/atg-core";

export default (
  rowData: any,
  isTablet: boolean,
  isMobile: boolean,
  theme: Theme
) => (
  <div style={{ background: theme.palette.grey[100] }}>
    <ListItem>
      <ListItemText
        primary="Reference:"
        secondary={HyphenFormatter(rowData.LoadNumber)}
      />
    </ListItem>
    <Divider />
    <List
      dense
      style={{
        display: "flex",
        flexDirection: !isMobile || !isTablet ? "column" : "row",
        alignItems: "start"
      }}
    >
      <ListItem>
        <ListItemText
          primary="Origin:"
          secondary={HyphenFormatter(rowData.Origin)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Destination:"
          secondary={HyphenFormatter(rowData.Destination)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Pickup Date:"
          secondary={HyphenFormatter(
            rowData.PickupDate
              ? moment(rowData.PickupDate).format("MM/DD/YYYY")
              : null
          )}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Delivery Date:"
          secondary={HyphenFormatter(
            rowData.DeliveryDate
              ? moment(rowData.DeliveryDate).format("MM/DD/YYYY")
              : null
          )}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Price:"
          secondary={HyphenFormatter(
            rowData.Price
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD"
                }).format(rowData.Price)
              : " -"
          )}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Drops:"
          secondary={HyphenFormatter(rowData.Drops)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Equipment:"
          secondary={HyphenFormatter(rowData.Equipment)}
        />
      </ListItem>
    </List>
    <List
      dense
      style={{
        display: "flex",
        flexDirection: !isMobile || !isTablet ? "column" : "row",
        alignItems: "start"
      }}
    >
      <ListItem>
        <ListItemText
          primary="Height:"
          secondary={HyphenFormatter(rowData.Height)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Weight:"
          secondary={HyphenFormatter(rowData.Weight)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Length:"
          secondary={HyphenFormatter(rowData.Length)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Volume:"
          secondary={HyphenFormatter(rowData.Volume)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Reference:"
          secondary={HyphenFormatter(rowData.Reference)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Mileage:"
          secondary={HyphenFormatter(rowData.Mileage)}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Comment:"
          secondary={HyphenFormatter(rowData.Comment)}
        />
      </ListItem>
    </List>
  </div>
);
