import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Typography, Link } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1">
        &copy;{" "}
        <Link
          component="a"
          href="https://www.armstrongtransport.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Armstrong Transport Group
        </Link>{" "}
        2020
      </Typography>
      <Typography variant="caption">All Rights Reserved</Typography>

      <Typography variant="caption">
        Questions? Problems? Glitches? Contact us at{" "}
        <Link
          aria-label="Link to open new message for carriers@armstrongtransport.com"
          color="inherit"
          href="mailto:carriers@armstrongtransport.com?subject=Public loadboard carrier support"
        >
          carriers@armstrongtransport.com
        </Link>
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
