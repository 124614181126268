import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { RouteWithLayout, ProtectedRouteWithLayout } from "./shared";
import { NotFoundRoute, SignInRoute, PublicLoadboardRoutes } from "views";
import { IRoute } from "@atg/atg-core";

const PublicRoutes = [SignInRoute, NotFoundRoute];
const ProtectedRoutes = [...PublicLoadboardRoutes];

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/public-loadboard" />

      {/* Routes without Auth required */}
      {ProtectedRoutes.map((routeProps: IRoute, index) => (
        <ProtectedRouteWithLayout
          key={`protectedLayout${index}`}
          {...routeProps}
        />
      ))}

      {/* Public Routes, no auth required */}
      {PublicRoutes.map((routeProps: IRoute, index) => (
        <RouteWithLayout key={`publicLayout${index}`} {...routeProps} />
      ))}

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
