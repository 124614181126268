import React from "react";
import { Formik, Form } from "formik";
import moment from "moment";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  useMediaQuery,
  Theme,
  CircularProgress,
} from "@material-ui/core";
import { TextInputWrapped, HyphenFormatter, PhoneNumberFormatter } from "@atg/atg-core";
import { LoadboardSchema } from "../utils/Validation";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@material-ui/lab/Alert";

const initalState = {
  selectedContact: {
    Id: 0,
    UserId: 0,
    LoadNumber: "",
    Origin: "",
    Destination: "",
    OriginCity: "",
    OriginState: "",
    OriginCountry: "",
    DestinationCity: "",
    DestinationState: "",
    DestinationCountry: "",
    PickupDate: Date(),
    DeliveryDate: Date(),
    Price: 0,
    Drops: 0,
    Equipment: "",
    Height: null,
    Weight: 0,
    Length: null,
    Volume: null,
    Reference: null,
    Comment: null,
    IsLoadFull: false,
    Mileage: 0,
  },
  contactForm: {
    message: "",
  },

  UserInfo: {},
};

interface Props {
  handleClose: Function;
  open: boolean;
  SelectedContact: {
    Id: number | null;
    UserId: number | null;
    LoadNumber: string | null;
    Origin: string | null;
    Destination: string | null;
    OriginCity: string | null;
    OriginState: string | null;
    OriginCountry: string | null;
    DestinationCity: string | null;
    DestinationState: string | null;
    DestinationCountry: string | null;
    PickupDate: string | null;
    DeliveryDate: string | null;
    Price: number | null;
    Drops: number | null;
    Equipment: string | null;
    Height: string | null;
    Weight: number | null;
    Length: string | null;
    Volume: string | null;
    Reference: string | null;
    Comment: string | null;
    IsLoadFull: boolean;
    Mileage: number | null;
  };
}

interface RootState {
  Login: {
    user: {
      email: string;
    };
    userInfo: {
      Contacts: Array<any>;
    };
    loggedIn: boolean;
    isLoading: boolean;
  };
  PublicLoadboard: {
    loadboardData: [];
    contactDetails: {
      ContactPhone: string;
    };
    isLoading: boolean;
    closeRequest: boolean;
  };
}

const ContactDialog: React.FC<Props> = ({
  handleClose,
  open,
  SelectedContact,
}) => {
  const submitContact = useDispatch();
  const Login = useSelector((state: RootState) => state.Login);
  const PublicLoadboard = useSelector(
    (state: RootState) => state.PublicLoadboard
  );

  const currentProfile = Login.userInfo.Contacts.find(
    (item: any) =>
      item.Status === "Active" &&
      item.Email?.toLowerCase() === Login.user.email.toLowerCase()
  );

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullScreen={useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))}
    >
      <Formik
        validateOnMount
        validationSchema={LoadboardSchema}
        initialValues={initalState.contactForm}
        onSubmit={(formValues) => {
          submitContact({
            type: "CONTACT_REQUEST",
            payload: {
              Contact: currentProfile,
              PostingId: SelectedContact.Id,
              Comments: formValues.message,
            },
          });
        }}
      >
        {({ values, isValid, errors, touched }) => (
          <Form>
            <DialogContent>
              <DialogContentText variant="h6">
                Posting Details:
              </DialogContentText>
              <DialogContentText color="textPrimary">
                Origin:{" "}
                {HyphenFormatter(`${SelectedContact.OriginCity}, 
                  ${SelectedContact.OriginState},  ${SelectedContact.OriginCountry}`)}
              </DialogContentText>
              <DialogContentText color="textPrimary">
                Pick Date:{" "}
                {SelectedContact.PickupDate
                  ? HyphenFormatter(
                      moment(SelectedContact.PickupDate).format("MM/DD/YYYY")
                    )
                  : "-"}
              </DialogContentText>
              <DialogContentText color="textPrimary">
                Destination:{" "}
                {HyphenFormatter(`${SelectedContact.DestinationCity}, 
                  ${SelectedContact.DestinationState}, 
                  ${SelectedContact.DestinationCountry}`)}
              </DialogContentText>
              <DialogContentText color="textPrimary">
                Drop Date:{" "}
                {SelectedContact.DeliveryDate
                  ? HyphenFormatter(
                      moment(SelectedContact.DeliveryDate).format("MM/DD/YYYY")
                    )
                  : " -"}
              </DialogContentText>
              <DialogContentText color="textPrimary">
                Rate:{" "}
                {SelectedContact.Price
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(SelectedContact.Price)
                  : " -"}
              </DialogContentText>
              <DialogContentText color="textPrimary">
                Phone:{" "}
                {PublicLoadboard.contactDetails.ContactPhone
                  ? PhoneNumberFormatter(PublicLoadboard.contactDetails.ContactPhone)
                  : " -"}
              </DialogContentText>
              <DialogContentText color="textPrimary">
                Equipment:{" "}
                {SelectedContact.Equipment ? SelectedContact.Equipment : " -"}
              </DialogContentText>
              <DialogContentText color="textPrimary">
                Note: {SelectedContact.Comment ? SelectedContact.Comment : " -"}
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogContent>
              <Alert severity="info">
                If any of your contact information is incorrect, please call our
                corporate office at 877-240-1181.
              </Alert>
            </DialogContent>
            <DialogContent>
              <DialogContentText variant="h6">
                Verify Contact Info
              </DialogContentText>
              <DialogContentText color="textPrimary">
                Name: {HyphenFormatter(currentProfile?.Name)}
              </DialogContentText>
              <DialogContentText color="textPrimary">
                Email: {HyphenFormatter(currentProfile?.Email)}
              </DialogContentText>
              <DialogContentText color="textPrimary">
                Phone: {HyphenFormatter(PhoneNumberFormatter(currentProfile?.Phone1))}
              </DialogContentText>
              <TextInputWrapped
                fullWidth
                multiline
                rowsMax={4}
                variant="outlined"
                label="message"
                name="message"
                value={values.message}
                error={Boolean(errors.message && touched.message)}
                helperText={
                  errors.message && touched.message ? errors.message : null
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose()} color="primary">
                Cancel
              </Button>
              <Button disabled={!isValid} type="submit" color="primary">
                {PublicLoadboard.isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  "Submit"
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ContactDialog;
