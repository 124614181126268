import * as yup from "yup";
import {ValidationMessages} from "@atg/atg-core";

export const SignInSchema = yup.object().shape({
  certType: yup.string(),
  certNumber: yup
    .string()
    .ensure()
    .required(ValidationMessages.REQUIRED),
  email: yup
    .string()
    .email(ValidationMessages.EMAIL)
    .required(ValidationMessages.REQUIRED),
});
