import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; //
import createSagaMiddleware from "redux-saga";
import * as RootReducers from "./rootReducer";
import RootSaga from "./rootSaga";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    ALKMaps?: any;
  }
}

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["Status", "PublicLoadboard"]
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    ...RootReducers
  })
);

export const storeConfig = () => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
  const persistor = persistStore(store);
  sagaMiddleware.run(RootSaga);
  return { store, persistor };
};
