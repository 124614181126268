import { call, put, takeEvery, all } from "redux-saga/effects";
import { AuthActions, BaseService } from "@atg/atg-core";
import { Actions as Status } from "redux-modules/status";

function* userLoginSaga({ payload }: any) {
  const { certNumber, certType, email } = payload;

  yield put({ type: AuthActions.USER_LOGIN_LOADING, payload: { isLoading: true } });

  try {
    const requestUrl = "/api/Loads/Authorize";

    const response = yield call(() =>
      BaseService.post(requestUrl, {
        Id: certNumber,
        Type: certType,
        Email: email
      })
    );

    response.data.Success
      ? yield put({ type: AuthActions.USER_LOGIN_COMPLETE, payload: response.data.data })
      : yield all([
          put({ type: AuthActions.USER_LOGIN_COMPLETE, payload: {} }),
          put(
            Status.addNotification({
              message: response.data.Message ? response.data.Message : response.data.message,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error"
              }
            })
          )
        ]);

    const isLoggedIn =
      response.data.Success && Object.keys(response.data.data).length > 0;

    yield put({ type: AuthActions.USER_AUTH, payload: isLoggedIn });
  } catch (error) {
    yield put(Status.DefaultError);
  }
}

export default function* loginSagas() {
  yield takeEvery(AuthActions.USER_LOGIN, userLoginSaga);
}
