import React from "react";
import { HyphenFormatter } from "@atg/atg-core";
import { Check, Clear } from "@material-ui/icons";

export default (isTablet: boolean, isMobile: boolean, equipment: any) => {
  // hidden only hides on true and material ui returns true in range
  const onlyMobile = !isMobile ? isMobile : isTablet;
  const tabletDesktop = isMobile ? !isMobile : !isTablet;
  const onlyDesktop = !isMobile ? !isMobile : !isTablet;
  return [
    {
      title: "Origin",
      field: "Origin",
      render: (rowItem: any) => HyphenFormatter(rowItem.Origin),
      emptyValue: "-",
      hidden: onlyMobile
    },
    {
      title: "Origin City",
      field: "OriginCity",
      render: (rowItem: any) => HyphenFormatter(rowItem.OriginCity),
      emptyValue: "-",
      hidden: onlyDesktop,
      filterCellStyle: { width: "2rem", maxWidth: "2rem" }
    },
    {
      title: "Origin State",
      field: "OriginState",
      render: (rowItem: any) => HyphenFormatter(rowItem.OriginState),
      emptyValue: "-",
      hidden: onlyDesktop,
      filterCellStyle: { width: "2rem", maxWidth: "1rem" }
    },
    {
      title: "Destination",
      field: "Destination",
      render: (rowItem: any) => HyphenFormatter(rowItem.Destination),
      emptyValue: "-",
      hidden: onlyMobile
    },
    {
      title: "Destination City",
      field: "DestinationCity",
      render: (rowItem: any) => HyphenFormatter(rowItem.DestinationCity),
      emptyValue: "-",
      hidden: onlyDesktop,
      filterCellStyle: { width: "2rem", maxWidth: "2rem" }
    },
    {
      title: "Destination State",
      field: "DestinationState",
      render: (rowItem: any) => HyphenFormatter(rowItem.DestinationState),
      emptyValue: "-",
      hidden: onlyDesktop,
      filterCellStyle: { width: "2rem", maxWidth: "1rem" }
    },
    {
      title: "Pick Date",
      field: "PickupDate",
      type: "date",
      emptyValue: "-",
      hidden: tabletDesktop,
      filterCellStyle: { width: "2rem", maxWidth: "2rem" }
    },
    {
      title: "Drop Date",
      field: "DeliveryDate",
      type: "date",
      emptyValue: "-",
      hidden: tabletDesktop,
      filterCellStyle: { width: "2rem", maxWidth: "2rem" }
    },
    {
      title: "Drops",
      field: "Drops",
      type: "numeric",
      filtering: false,
      emptyValue: "-",
      hidden: tabletDesktop,
      cellStyle: { maxWidth: "2rem" }
    },
    {
      title: "Weight",
      field: "Weight",
      type: "numeric",
      filtering: false,
      emptyValue: "-",
      hidden: onlyDesktop
    },
    {
      title: "Equipment",
      field: "Equipment",
      emptyValue: "-",
      hidden: onlyDesktop,
      filterCellStyle: { width: "2rem", maxWidth: "2rem" },
      lookup: equipment
    },
    {
      title: "Miles",
      field: "Mileage",
      type: "numeric",
      filtering: false,
      hidden: onlyDesktop,
      emptyValue: "-"
    },
    {
      title: "Rate",
      field: "Price",
      type: "currency",
      filtering: false,
      hidden: onlyDesktop,
      emptyValue: "-"
    },
    {
      title: "Load Full",
      field: "IsLoadFull",
      filtering: false,
      render: (rowItem: any) =>
        rowItem.IsLoadFull ? <Check /> : <Clear color="error" />,
      emptyValue: "-",
      hidden: onlyDesktop
    },
    {
      title: "Reference",
      field: "LoadNumber",
      emptyValue: "-",
      filterCellStyle: { maxWidth: "2rem" }
    }
  ];
};
