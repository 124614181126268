import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Button, makeStyles } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { SignInSchema } from "./utils/Validation";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Link,
  Box,
  Typography,
  Container,
  CircularProgress,
} from "@material-ui/core";
import { IRoute, TextInputWrapped } from "@atg/atg-core";
import { Minimal } from "layouts";

function Copyright() {
  return (
    <div>
      <Typography variant="body1" color="textSecondary" align="center">
        having trouble logging in? contact us at{" "}
        <Link
          aria-label="Link to open new message for carriers@armstrongtransport.com"
          color="inherit"
          href="mailto:carriers@armstrongtransport.com?subject=Public loadboard carrier support"
        >
          carriers@armstrongtransport.com
        </Link>
      </Typography>
      <br />
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link
          aria-label="Link to Armstrong Transport homepage."
          color="inherit"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.armstrongtransport.com/"
        >
          Armstrong Transport Group
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
}

const initalState = {
  formValues: {
    certType: "MC",
    certNumber: "",
    email: "",
  },
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  optionType: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
}));

interface RootState {
  Login: {
    user: object;
    userInfo: object;
    isLoading: boolean;
    isLoggedIn: boolean;
  };
}

export function SignInView(props: any) {
  const { history } = props;
  const classes = useStyles();
  const userLogin = useDispatch();

  const LoginStore = useSelector((state: RootState) => state.Login);

  useEffect(() => {
    Object.keys(LoginStore.userInfo).length > 0 &&
      history.push("/public-loadboard");
  }, [LoginStore.userInfo, history]);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Carrier Login
        </Typography>
        <Formik
          validateOnMount
          initialValues={initalState.formValues}
          validationSchema={SignInSchema}
          onSubmit={(formValues: any) => {
            userLogin({ type: "USER_LOGIN", payload: formValues });
          }}
        >
          {({ values, handleChange, errors, isValid, touched }) => (
            <Form autoComplete="on">
              <RadioGroup
                className={classes.optionType}
                aria-label="Certification Number"
                name="certType"
                value={values.certType}
                onChange={handleChange}
              >
                <FormControlLabel value="MC" control={<Radio />} label="MC" />
                <FormControlLabel value="DOT" control={<Radio />} label="DOT" />
              </RadioGroup>

              <TextInputWrapped
                autoFocus
                fullWidth
                variant="outlined"
                margin="normal"
                id="certNumber"
                label="MC / DOT Number"
                name="certNumber"
                value={values.certNumber}
                autoComplete="certNumber"
                error={Boolean(errors.certNumber && touched.certNumber)}
                helperText={
                  errors.certNumber && touched.certNumber
                    ? errors.certNumber
                    : null
                }
              />

              <TextInputWrapped
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={values.email}
                autoComplete="email"
                error={Boolean(errors?.email && touched?.email)}
                helperText={errors.email && touched.email ? errors.email : null}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!isValid}
              >
                {LoginStore.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Sign In"
                )}
              </Button>
            </Form>
          )}
        </Formik>
        <Typography>
          Don't have an account yet?{" "}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Link to Carrier Onboarding Portal"
            href="https://mycarrierpackets.com/476a6f2b-a7a0-4e2f-8cd9-387f180b8de3/Carrier/Intellivite/CarrierTeamATG"
          >
            Register
          </Link>
        </Typography>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export const SignInRoute: IRoute = {
  exact: true,
  pageTitle: "Sign in",
  component: SignInView,
  layout: Minimal,
  path: "/sign-in",
};
