export default (data: any) => {
  if (typeof data == ("undefined" || null)) {
    return [];
  }
  return Object.values(data).flatMap((item: any) => ({
    ...item,
    Origin: `${item.OriginCity}, ${item.OriginState}, ${item.OriginCountry}`,
    Destination: `${item.DestinationCity}, ${item.DestinationState}, ${item.DestinationCountry}`
  }));
};
