import React from "react";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { Theme } from "@atg/atg-core";
import Routes from "./Routes";
// import { ApplicationInsights, ITelemetryPlugin  } from "@microsoft/applicationinsights-web";
// import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import "./App.css";

const browserHistory = createBrowserHistory();

// let reactPlugin = new ReactPlugin();
// let appInsights = new ApplicationInsights({
//   config: {
//     instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
//     extensions: [reactPlugin as ITelemetryPlugin],
//     extensionConfig: {
//       [reactPlugin.identifier]: { history: browserHistory }
//     }
//   }
// });

// if (process.env.NODE_ENV === "production") {
//   appInsights.loadAppInsights();
// }

const App: React.FC = () => {
  return (
    <div className="App">
      <Theme>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </Theme>
    </div>
  );
};

export default App;
