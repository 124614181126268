import { ADD_NOTIFICATION, CLOSE_NOTIFICATION, DISMISS_NOTIFICATION} from "./Actions";

const initalState = {
  notifications: []
};

export default (state = initalState, action: any) => {
  const { type, key, notification, dismissAll } = action;

  switch (type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: key,
            ...notification
          }
        ]
      };

    case CLOSE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((notificationItem: any) =>
          dismissAll || notificationItem.key === key
            ? { ...notificationItem, dismissed: true }
            : { ...notificationItem }
        )
      };

    case DISMISS_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notificationItem: any) => notificationItem.key !== key
        )
      };

    default:
      return state;
  }
};
