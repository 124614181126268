import {
  PUBLIC_LOADBOARD_LOADING,
  PUBLIC_LOADBOARD_COMPLETE,
  CONTACT_REQUEST_LOADING,
  CONTACT_REQUEST_COMPLETE,
  CONTACT_DETAILS_REQUEST_LOADING,
  CONTACT_DETAILS_REQUEST_COMPLETE
} from "./Actions";

const initialState = {
  loadboardData: null,
  contactDetails: {},
  isLoading: false,
  closeRequest: false,
  publicBoardLoading: false
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case PUBLIC_LOADBOARD_COMPLETE:
      return {
        ...state,
        loadboardData: payload,
        isLoading: false,
        publicBoardLoading: false
      };

    case PUBLIC_LOADBOARD_LOADING:
      const { isLoading } = payload;
      return {
        ...state,
        publicBoardLoading: true,
        isLoading
      };

    case CONTACT_REQUEST_COMPLETE:
      return {
        ...state,
        isLoading: false,
        closeRequest: payload
      };

    case CONTACT_REQUEST_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case CONTACT_DETAILS_REQUEST_COMPLETE:
      return {
        ...state,
        contactDetails: payload
      };

    case CONTACT_DETAILS_REQUEST_LOADING:
      return {
        ...state
      };

    default:
      return state;
  }
};
