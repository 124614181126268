import React, { FunctionComponent } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { IRoute } from "@atg/atg-core";

interface ProtectedRouteWithLayoutProps extends IRoute {}

const ProtectedRouteWithLayout: FunctionComponent<ProtectedRouteWithLayoutProps> = (
  props
) => {
  const { layout: Layout, component: Component, pageTitle, ...rest } = props;

  const Login = useSelector(({ Login }: any) => Login);

  return Login.loggedIn ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout pageTitle={pageTitle}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  ) : (
    <Redirect
      to={{
        pathname: "/sign-in",
      }}
    />
  );
};

export default ProtectedRouteWithLayout;
