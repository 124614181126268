import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Grid, Typography, Theme, Button } from "@material-ui/core";
import NotFoundBG from "images/undraw_not_found_60pq.svg";
import { IRoute } from "@atg/atg-core";
import { Minimal } from "layouts";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
    },
    content: {
      textAlign: "center",
    },
    image: {
      marginTop: 50,
      display: "inline-block",
      maxWidth: "100%",
      width: 560,
    },
    returnButton: {
      margin: "3rem",
    },
  })
);

export const NotFoundView = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h2">
              404: The page you are looking for isn’t here
            </Typography>
            <Typography variant="subtitle2">
              You either tried some shady route or you came here by mistake.
              Whichever it is, try using the navigation
            </Typography>
            <Button
              className={classes.returnButton}
              color="primary"
              variant="contained"
              //href behaves like an a tag, normally you would use a useHistory hook from react-router-dom, but the app should be able to resolve this since its just a simple route change
              href="/"
            >
              Take me back home
            </Button>
            <img
              alt="Page Not Found"
              className={classes.image}
              src={NotFoundBG}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export const NotFoundRoute: IRoute = {
  exact: true,
  pageTitle: "Not Found",
  component: NotFoundView,
  layout: Minimal,
  path: "/not-found",
};
