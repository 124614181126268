import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Divider,
  Drawer,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

import { Dashboard } from "@material-ui/icons";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { Profile, SidebarNav } from "./components";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)"
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const pages = [
  {
    title: "Loadboard",
    icon: <Dashboard />
  }
];

const navOptions = pages.reduce((acc, value) => {
  acc[value.title] = false;
  return acc;
}, {});

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();
  const [openSublist, setOpenSublist] = React.useState(navOptions);

  const handleClick = title => {
    setOpenSublist({ ...openSublist, [title]: !openSublist[title] });
  };

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        {pages.map(listGroup => (
          <React.Fragment>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <ListItem button onClick={() => handleClick(listGroup.title)}>
                <ListItemIcon>{listGroup.icon}</ListItemIcon>
                <ListItemText primary={listGroup.title} />
                {openSublist[listGroup.title] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openSublist[listGroup.title]}
                timeout="auto"
                unmountOnExit
              >
                <SidebarNav className={classes.nav} pages={listGroup.subList} />
              </Collapse>
            </List>
          </React.Fragment>
        ))}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
