import React, { FunctionComponent } from "react";
import { Route } from "react-router-dom";
import { IRoute } from "@atg/atg-core";

interface RouteWithLayout extends IRoute {}

const RouteWithLayout: FunctionComponent<RouteWithLayout> = (props) => {
  const { layout: Layout, component: Component, pageTitle, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout pageTitle={pageTitle}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;
