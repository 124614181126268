import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Actions } from "redux-modules/status";

let displayed: any = [];

interface RootState {
  Status: {
    notifications: [];
  };
}

function getOnlineStatus() {
  return typeof navigator !== "undefined" &&
    typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;
}

function useOnlineStatus() {
  const [onlineStatus, setOnlineStatus] = useState(getOnlineStatus);

  const goOnline = () => setOnlineStatus(true);

  const goOffline = () => setOnlineStatus(false);

  useEffect(() => {
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  return onlineStatus;
}

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state: RootState) => state.Status.notifications || []
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onlineStatus = useOnlineStatus();

  const storeDisplayed = (id: any) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: any) => {
    displayed = [...displayed.filter((key: any) => id !== key)];
  };

  useEffect(() => {
    !onlineStatus &&
      dispatch(
        Actions.addNotification({
          message: "No Connection",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
          }
        })
      );
  }, [onlineStatus, dispatch]);

  useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }: any) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event: any, reason: any, myKey: any) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event: any, myKey: any) => {
            // remove this snackbar from redux store
            dispatch(Actions.dismissNotification(myKey));
            removeDisplayed(myKey);
          }
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;