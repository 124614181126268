import React, { useEffect, useCallback } from "react";
import { IRoute, TableWrapped } from "@atg/atg-core";
import { useMediaQuery, Theme, useTheme } from "@material-ui/core";
import { PublicBoardColumns, PublicBoardRowDetail } from "./components";
import { useSelector, useDispatch } from "react-redux";
import ContactDialog from "./contact-dialog/ContactDialog";
import RemapTableData from "./utils/RemapTableData";
import { Main } from "layouts";

declare global {
  interface Window {
    ATG: any;
  }
}

const initalState = {
  selectedContact: {
    Id: 0,
    UserId: 0,
    LoadNumber: "",
    Origin: "",
    Destination: "",
    OriginCity: "",
    OriginState: "",
    OriginCountry: "",
    DestinationCity: "",
    DestinationState: "",
    DestinationCountry: "",
    PickupDate: Date(),
    DeliveryDate: Date(),
    Price: 0,
    Drops: 0,
    Equipment: "",
    Height: null,
    Weight: 0,
    Length: null,
    Volume: null,
    Reference: null,
    Comment: null,
    IsLoadFull: false,
    Mileage: 0,
  },
  contactForm: {
    message: "",
  },
  DashboardData: [],
  UserInfo: {},
};

interface RootState {
  Login: {
    user: {
      email: string;
    };
    userInfo: {
      Contacts: Array<any>;
    };
    loggedIn: boolean;
    isLoading: boolean;
  };
  PublicLoadboard: {
    loadboardData: [];
    contactDetails: {
      ContactPhone: string;
    };
    isLoading: boolean;
    closeRequest: boolean;
  };
}

export const PublicLoadBoardView: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [SelectedContact, setSelectedContact] = React.useState(
    initalState.selectedContact
  );

  const theme = useTheme();
  const dispatch = useDispatch();
  const Login = useSelector((state: RootState) => state.Login);
  const PublicLoadboard = useSelector(
    (state: RootState) => state.PublicLoadboard
  );

  useEffect(() => {
    Login.loggedIn &&
      PublicLoadboard.loadboardData === null &&
      dispatch({ type: "LOADBOARD" });
  }, [PublicLoadboard.loadboardData, Login.loggedIn, dispatch]);

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });

  const handleClickOpen = (data: any) => {
    dispatch({ type: "CONTACT_DETAILS", payload: data.Id });
    setSelectedContact(data);
    setOpen(true);
  };

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  useEffect(() => {
    PublicLoadboard.closeRequest && handleClose();
  }, [PublicLoadboard.closeRequest, handleClose]);

  const DashboardData =
    PublicLoadboard.loadboardData === null
      ? initalState.DashboardData
      : RemapTableData(PublicLoadboard.loadboardData);

  const equipmentOptions = window.ATG?.Data.Equipments;

  return (
    <div style={{ maxWidth: "100%", margin: "1rem" }}>
      <ContactDialog
        handleClose={handleClose}
        open={open}
        SelectedContact={SelectedContact}
      />

      <TableWrapped
        searchable
        columns={PublicBoardColumns(isTablet, isMobile, equipmentOptions)}
        data={DashboardData}
        title="Available Loads"
        options={{
          pageSize: 100,
          pageSizeOptions: [100, 200, 500],
          filtering: true,
          actionsColumnIndex: -1,
          debounceInterval: 200,
          paginationType: "normal",
          columnsButton: true,
        }}
        onRowClick={(_event: any, rowData: any) => handleClickOpen(rowData)}
        detailPanel={[
          {
            tooltip: "Show Details",
            render: (rowData: any) =>
              PublicBoardRowDetail(rowData, isTablet, isMobile, theme),
          },
        ]}
      />
    </div>
  );
};

export const PublicLoadBoardRoute: IRoute = {
  exact: true,
  pageTitle: "Public Loadboard",
  component: PublicLoadBoardView,
  layout: Main,
  path: "/public-loadboard",
};
