import { call, put, takeEvery, all } from "redux-saga/effects";
import {
  LOADBOARD,
  PUBLIC_LOADBOARD_LOADING,
  PUBLIC_LOADBOARD_COMPLETE,
  CONTACT_REQUEST,
  CONTACT_REQUEST_LOADING,
  CONTACT_REQUEST_COMPLETE,
  CONTACT_DETAILS,
  CONTACT_DETAILS_REQUEST_LOADING,
  CONTACT_DETAILS_REQUEST_COMPLETE
} from "./Actions";
import { BaseService } from "@atg/atg-core";
import { Actions as Status } from "redux-modules/status";

function* loadDashboardSaga() {
  yield put({ type: PUBLIC_LOADBOARD_LOADING, payload: { isLoading: true } });

  try {
    const requestUrl = `/odata/CarrierLoadboard?$orderby=PickupDate`;

    const response = yield call(() => BaseService.get(requestUrl));

    response.data.value === []
      ? yield put({ type: PUBLIC_LOADBOARD_COMPLETE, payload: null })
      : yield put({ type: PUBLIC_LOADBOARD_COMPLETE, payload: response.data.value });

    typeof response.data.value !== "undefined"
      ? yield put(
          Status.addNotification({
            message: "Loadboard sync successful",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success"
            }
          })
        )
      : yield put(
          Status.addNotification({
            message: "Loadboard sync unsuccessful",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error"
            }
          })
        );
  } catch (error) {
    yield put(Status.DefaultError);
  }
}

function* contactDetailsSaga(reqBody: any) {
  yield put({ type: CONTACT_DETAILS_REQUEST_LOADING });

  try {
    const requestUrl = `/api/ExternalLoads/${reqBody.payload}`;
    const response = yield call(() => BaseService.get(requestUrl));

    yield put({ type: CONTACT_DETAILS_REQUEST_COMPLETE, payload: response });
  } catch (error) {
    yield put(Status.DefaultError);
  }
}

function* contactSaga(reqBody: any) {
  yield put({ type: CONTACT_REQUEST_LOADING });

  try {
    const requestUrl = `/api/Loads/Contact`;

    const response = yield call(() =>
      BaseService.post(requestUrl, { ...reqBody.payload })
    );

    yield put({ type: CONTACT_REQUEST_COMPLETE, payload: response.Success });

    response.Success
      ? yield all([
          put(
            Status.addNotification({
              message: response.Message
                ? response.Message
                : "Message was successfully sent",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "success"
              }
            })
          ),
          yield put({ type: CONTACT_REQUEST_COMPLETE, payload: false })
        ])
      : yield put(
          Status.addNotification({
            message: response.Message
              ? response.Message
              : "Unable to send message",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error"
            }
          })
        );
  } catch (error) {
    yield put(Status.DefaultError);
  }
}

export default function* loginSagas() {
  yield takeEvery(CONTACT_DETAILS, contactDetailsSaga);
  yield takeEvery(CONTACT_REQUEST, contactSaga);
  yield takeEvery(LOADBOARD, loadDashboardSaga);
}
